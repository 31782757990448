import React, {useState, useEffect} from "react";
import {Menu} from 'antd';
import {Link} from "react-router-dom";
import data from "./data";

interface Props {
    mode : "vertical" | "horizontal" | "inline"
}

const Menus : React.FC<Props> = ({mode}) => {

    const [selectedKeys, setSelectedKeys] = useState<string>('');

    useEffect(() => {

        let pathname : string = window.location.pathname;

        setSelectedKeys(pathname);
    }, [])

    return (
        <Menu
            mode={mode}
            defaultSelectedKeys={['/']}
            selectedKeys={[selectedKeys]}
        >
            <Menu.Item key="/">
                <Link to={"/"}>{data.en.home}</Link>
            </Menu.Item>
            <Menu.Item key="/about" >
                <Link to={"/about"}>{data.en.about}</Link>
            </Menu.Item>
            <Menu.Item key="/product">
                <Link to={"/product"}>{data.en.product}</Link>
            </Menu.Item>
            <Menu.Item key="/team">
                <Link to={"/team"}>{data.en.team}</Link>
            </Menu.Item>
        </Menu>
    )
}

export default React.memo(Menus);