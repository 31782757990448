import React from "react";
import {Button, Col, Row, Space} from "antd";
import {
    FacebookOutlined,
    InstagramOutlined,
    TwitterOutlined,
    PhoneOutlined,
    WhatsAppOutlined,
    MailOutlined,
    HomeOutlined
}
from "@ant-design/icons";
import data from "./data";
import ReactGA from "react-ga";

const Footer : React.FC = () => {
    return (
        <footer id={"contact"} className={"public-footer"}>
            <div className={"public-container"}>
                <Row gutter={[300, 50]}>
                    <Col lg={12} md={12} sm={24} xs={24}>
                       <div className={"section-heading"}>
                           <h2>{data.id.title}</h2>
                           <p>{data.id.subTitle}</p>
                       </div>
                        <div className={"social-media"}>
                            <Space size={"large"}>
                                <a
                                    href={"https://www.facebook.com/PerawatUI"}
                                    target={"_blank"}
                                    rel={"noreferrer"}
                                    onClick={() => ReactGA.event({category : "Social_Media", action : "clicked", label : "facebook_footer" })}
                                >
                                    <FacebookOutlined />
                                </a>
                                <a
                                    href={"https://www.instagram.com/vit_rnurse/"}
                                    target={"_blank"}
                                    rel={"noreferrer"}
                                    onClick={() => ReactGA.event({category : "Social_Media", action : "clicked", label : "instagram_footer" })}
                                >
                                    <InstagramOutlined />
                                </a>
                                <a
                                    href={"https://twitter.com/KeperawatanUI"}
                                    target={"_blank"}
                                    rel={"noreferrer"}
                                    onClick={() => ReactGA.event({category : "Social_Media", action : "clicked", label : "twitter_footer" })}
                                >
                                    <TwitterOutlined />
                                </a>
                            </Space>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <div className={"contact"}>
                            <div className={"phone"}>
                                <Space size={"middle"}>
                                    <PhoneOutlined />
                                    +62 823-4173-6912 (Admin)
                                </Space>
                            </div>
                            <div className={"wa"}>
                                <Space size={"middle"}>
                                    <WhatsAppOutlined />
                                    +62 823-4173-6912 (Admin)
                                </Space>
                            </div>
                            <div className={"email"}>
                                <Space size={"middle"}>
                                    <MailOutlined />
                                    vitrnurse@gmail.com
                                </Space>
                            </div>
                            <div className={"address"}>
                                <Space align={"start"} size={"middle"}>
                                    <HomeOutlined />
                                    Gedung Pendidikan dan Laboratorium FIK UI
                                    Jl. Prof. Dr. Bahder Djohan, Kampus UI Depok
                                    Jawa Barat 16424 – Indonesia.
                                </Space>
                            </div>
                            <div className={"footer-buttons"}>
                                <Space size={"middle"}>
                                    <Button
                                        size={"large"}
                                        ghost={true}
                                        href={"https://goo.gl/maps/kagZqFRpypnXE91t5 \n"}
                                        target={"_blank"}
                                        rel={"noreferrer"}
                                        onClick={() => ReactGA.event({category : "Contact", action : "clicked", label : "get_direction" })}
                                    >
                                        Get Direction
                                    </Button>
                                    <Button
                                        size={"large"}
                                        ghost={true}
                                        href={"tel:+622341736912"}
                                        target={"_blank"}
                                        rel={"noreferrer"}
                                        onClick={() => ReactGA.event({category : "Contact", action : "clicked", label : "call_now" })}
                                    >
                                        Call Now
                                    </Button>
                                    <Button
                                        size={"large"}
                                        ghost={true}
                                        href={"https://api.whatsapp.com/send/?phone=6282341736912&text&app_absent=0"}
                                        target={"_blank"}
                                        rel={"noreferrer"}
                                        onClick={() => ReactGA.event({category : "Contact", action : "clicked", label : "chat" })}
                                    >
                                        Chat
                                    </Button>
                                </Space>
                            </div>
                        </div>
                    </Col>
                </Row>
                <p>VIT-R NURSE FOR BETTER LEARN</p>
            </div>
        </footer>
    )
}

export default Footer;