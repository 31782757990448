const data = {
    id : {
        home    : "Rumah",
        about   : "Tentang",
        product : "Produk",
        team    : "Tim",
    },
    en : {
        home    : "Home",
        about   : "About",
        product : "Product",
        team    : "Team",
    }
}

export default data