import {useCallback, useState} from "react";
import firebase from "firebase/app";
import {User} from "interfaces";
import {notification, Modal, Form, Input} from "antd";
import {useHistory} from "react-router-dom";
import "firebase/functions";
import "firebase/auth";

export function useAuth() {

    const [loading, setLoading] = useState<boolean>(false);

    const form    = Form.useForm();
    const history = useHistory();

    const register = useCallback(async (user : User) : Promise<void> => {
        try {

            setLoading(true);

            // const existingUser =  await firebase.firestore()
            //     .collection("user")
            //     .where("email", "==", user.email)
            //     .get()
            //
            // if (existingUser.empty) {
            //
            //     const ref = firebase.firestore().collection("user").doc();
            //
            //     await ref.set({ ...user, isDisable : true, timestamp : new Date()});
            //
            //     history.push("/register/success/" + ref.id);
            //
            // } else {
            //
            //     notification.error({
            //         message: "Failed to sign up.",
            //         description : "The email address is already in use by another account.",
            //     })
            // }

            const signup = firebase.app().functions("asia-southeast1").httpsCallable("processSignUp")

            await signup({...user});

            history.push("/register/success/thanks");

        } catch (e) {

            const message = e.details.message;

            notification.error({
                message: "Failed to sign up.",
                description : message,
            })

        } finally {

            setLoading(false);
        }
    }, [history]);

    const login = useCallback( async ()  : Promise<void> => {
        Modal.confirm({
            title : "",
            centered : true,
            okText : "Sign In",
            icon : null,
            okButtonProps: {style : {borderRadius : 10}, size : "large"},
            cancelButtonProps : {style : {borderRadius : 10}, size : "large"},
            onOk : async () => {
                try {

                    const values = await form[0].validateFields();

                    await firebase.auth().signInWithEmailAndPassword(values.email, values.password);

                    window.location.href = "/manage/profile";

                    form[0].resetFields();

                } catch (e) {

                    const message = e.message === "The user account has been disabled by an administrator."
                        ? "Mohon maaf akun anda belum diaktifkan oleh admin.."
                        : e.message

                    notification.error({
                        message: "Gagal Masuk",
                        description : message,
                    })
                }
            },
            content : (
                <div style={{padding : "10px 20px"}}>
                    <Form layout={"vertical"} form={form[0]}>
                        <Form.Item style={{textAlign: "center", marginTop : 20}}>
                            <div className={"login-logo"}>
                                <img width={265} src={"/logo.png"} alt={""} />
                            </div>
                        </Form.Item>

                        <Form.Item
                            label={"Email"}
                            name={"email"}
                            rules={[{required : true, message : ""}]}
                        >
                            <Input size={"large"}  style={{borderRadius : 10}} />
                        </Form.Item>

                        <Form.Item
                            label={"Password"}
                            name={"password"}
                            rules={[{required : true, message : ""}]}
                        >
                            <Input.Password  style={{borderRadius : 10}} size={"large"} />
                        </Form.Item>
                    </Form>
                </div>
            )
        })
    }, [form]);

    const logout = useCallback( async () : Promise<void> => {
        try {

            await firebase.auth().signOut();

        } catch (e) {

            notification.error({
                message: "Failed to sign out.",
                description : e.message,
            })
        }
    }, [])

    return {
        loading,
        login,
        register,
        logout
    }
}