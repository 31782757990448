import React from 'react';

interface Pages {
    path      : string,
    isExact   : boolean,
    component : React.LazyExoticComponent<React.FC>,
    isPublic  : boolean
}

let pages : Pages[] = [
    {
        path      : "/",
        isExact   : true,
        component : React.lazy(() => import('../pages/home')),
        isPublic  : true,
    },
    {
        path      : "/about",
        isExact   : true,
        component : React.lazy(() => import('../pages/about')),
        isPublic  : true,
    },
    {
        path      : "/product",
        isExact   : true,
        component : React.lazy(() => import('../pages/product')),
        isPublic  : true,
    },
    {
        path      : "/team",
        isExact   : true,
        component : React.lazy(() => import('../pages/team')),
        isPublic  : true,
    },
    {
        path      : "/register/:product?/:success?",
        isExact   : true,
        component : React.lazy(() => import('../pages/register')),
        isPublic  : true,
    },
    {
        path      : "/manage/profile",
        isExact   : true,
        component : React.lazy(() => import('../pages/profile')),
        isPublic  : false,
    },
    {
        path      : "/product/perekaman-ekg",
        isExact   : true,
        component : React.lazy(() => import('../pages/scenario')),
        isPublic  : false,
    },
    {
        path      : "/product/pemasangan-kateter-intravena",
        isExact   : true,
        component : React.lazy(() => import('../pages/scenario')),
        isPublic  : false,
    },
    {
        path      : "/product/pemasangan-NGT",
        isExact   : true,
        component : React.lazy(() => import('../pages/scenario')),
        isPublic  : false,
    },
    {
        path      : "/product/pemasangan-kateter-urin",
        isExact   : true,
        component : React.lazy(() => import('../pages/scenario')),
        isPublic  : false,
    },
    {
        path      : "/product/pemberian-obat",
        isExact   : true,
        component : React.lazy(() => import('../pages/scenario')),
        isPublic  : false,
    },
    {
        path      : "/manage/skenario-vr-oculus",
        isExact   : true,
        component : React.lazy(() => import('../pages/instruction')),
        isPublic  : false,
    }
]

export default pages