import Publiclayout from "./layout";
import Metadecorator from "./Metadecorator";
import Loader from "./loader";

const component = {
    Publiclayout,
    Metadecorator,
    Loader,
};

export default component