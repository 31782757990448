import React, {useContext} from "react";
import {Button, Drawer, Space} from "antd";
import {DrawerContext} from "contexts/drawer";
import Menu from "../menu";
import {Link} from "react-router-dom";
import {SessionContext} from "../../contexts/sessions";
import {useAuth} from "hooks/auth";
import {LogoutOutlined, UserOutlined} from "@ant-design/icons";

const Sider : React.FC = () => {

    const [authenticated] = useContext(SessionContext);
    const [visible, setVisible] = useContext(DrawerContext);
    const auth = useAuth();

    return (
        <Drawer
            title={null}
            closable={false}
            onClose={() => setVisible(false)}
            visible={visible}
            style={{padding: 0}}
        >
           <div className={"public-drawer"} style={{width: "100%"}}>
               <Menu mode={"vertical"} />
               <div className={"social-media"}>
                   {
                       !authenticated ? (
                           <Space size={"large"}>
                               <Button
                                   type={"link"}
                                   onClick={() => auth.login()}
                               >
                                   <b>Login</b>
                               </Button>
                               <Link to={"/register"}>
                                   <Button type={"primary"}>
                                       <b>Sign Up</b>
                                   </Button>
                               </Link>
                           </Space>
                       ) : (
                           <div>
                               {/*<Space>*/}
                               {/*    <Avatar style={{cursor : "pointer"}} src={`${user?.photoURL}?d=wavatar&&s=200`} />*/}
                               {/*    <b>{user?.displayName}</b>*/}
                               {/*</Space>*/}
                               {/*<div style={{marginTop: 10}} />*/}
                               <Space direction={"vertical"} size={"large"}>
                                   <Link to={"/manage/profile"}>
                                       <UserOutlined /> &nbsp;&nbsp;&nbsp;Dashboard
                                   </Link>
                                   <span
                                       onClick={() => auth.logout()}
                                       style={{cursor : "pointer"}}
                                   >
                                    <LogoutOutlined /> &nbsp;&nbsp;&nbsp;Logout
                                </span>
                               </Space>
                           </div>
                       )
                   }
               </div>
           </div>
        </Drawer>
    )
}

export default React.memo(Sider);