import React, {useContext, useEffect} from "react";
import {Avatar, Button, Layout, Popover, Space} from "antd";
import {MenuOutlined, UserOutlined, LogoutOutlined} from "@ant-design/icons";
import {DrawerContext} from "contexts/drawer";
import {Link} from "react-router-dom";
import {useAuth} from "hooks/auth";
import Menu from "../menu";
import {SessionContext} from "contexts/sessions";
// import ReactGA from 'react-ga';

const Header: React.FC = () => {

    const [authenticated,, user] = useContext(SessionContext);
    const [, setVisible] = useContext(DrawerContext);

    const auth = useAuth();

    useEffect(() => {

        let navbar = document.getElementById("navbar");

        window.onscroll = () => {
            if (window.pageYOffset > 100) {
                navbar?.classList.add("scrolled")
            } else {
                navbar?.classList.remove("scrolled")
            }
        }
    }, []);

    return (
        <Layout.Header id={"navbar"} className="public-header">
           <div className={"public-container"}>

               <div className={"logo"}>
                   <Link to={"/"}>
                       <img width={100} src={"/logo.webp"} alt={"Logo"}/>
                   </Link>
               </div>

               <div className={"social-media"}>
                   {
                       !authenticated ? (
                           <Space size={"large"}>
                               <Button
                                   type={"link"}
                                   onClick={() => auth.login()}
                               >
                                   <b>Login</b>
                               </Button>
                               <Link to={"/register"}>
                                   <Button type={"primary"}>
                                       <b>Sign Up</b>
                                   </Button>
                               </Link>
                           </Space>
                       ) : (
                           <Space size={"large"}>
                               <b>{user?.displayName}</b>
                               <Popover
                                trigger={"click"}
                                title={null}
                                placement={"bottom"}
                                content={
                                    <div className={"popover-header"}>
                                        <Space direction={"vertical"} size={"large"}>
                                            <Link to={"/manage/profile"}>
                                                <UserOutlined /> &nbsp;&nbsp;&nbsp;Dashboard
                                            </Link>
                                            <span
                                                onClick={() => auth.logout()}
                                                style={{cursor : "pointer"}}
                                            >
                                            <LogoutOutlined /> &nbsp;&nbsp;&nbsp;Logout
                                        </span>
                                        </Space>
                                    </div>
                                }
                               >
                                   <Avatar style={{cursor : "pointer"}} src={`${user?.photoURL}?d=wavatar&&s=200`} />
                               </Popover>
                           </Space>
                       )
                   }
               </div>

               <div className={"button-menu"}>
                   <Button shape={"circle"} type={"primary"} onClick={() => setVisible(true)}>
                       <MenuOutlined />
                   </Button>
               </div>

               <div className={"navigation"}>
                   <Menu mode={"horizontal"} />
               </div>

           </div>
        </Layout.Header>
    )
}

export default React.memo(Header);